<template>
    <v-row class="centro">
      <v-col class="align-center">
        <v-data-table
          dense
          :headers="headers"
          :items="clientes"
          item-key="id"
          height="auto"
          locale="pt-BR"
          class="elevation-10 mb-10"
          :search="search"
        >
          <template v-slot:item.id_cidade="{ item }" >
            {{ buscaCidade(item) }}
          </template>

          <template v-slot:item.id_bairro="{ item }" >
            {{ buscaBairro(item) }}
          </template>

          <template v-slot:top>
            <v-toolbar id="tb" flat>
              <svg-icon height="30px" width="30px" type="mdi" :path="icone"></svg-icon>
              <v-toolbar-title>Cadastro de Clientes</v-toolbar-title>
              <v-divider class="mx-4" inset vertical></v-divider>
              <svg-icon class="mt-2" type="mdi" :path="pesquisar"></svg-icon>
              <v-text-field
                v-model="search"
                label="Pesquisar"
                class="ml-1 mt-7 mr-7"
              ></v-text-field>
              <v-btn
                color="black"
                dark
                class="mb-2"
                @click="newItem()"
              >
                Novo Cliente
              </v-btn>

              <v-dialog v-model="dialogDelete" max-width="500px">
                <v-card>
                  <v-card-title class="text-h5">
                    Tem certeza que deseja excluir o cliente
                    {{editedItem.nome}}?
                  </v-card-title>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="closeDelete">
                      Cancelar
                    </v-btn>
                    <v-btn color="blue darken-1" text @click="deleteItemConfirm">
                      Excluir
                    </v-btn>
                    <v-spacer></v-spacer>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </v-toolbar>
          </template>
          <template v-slot:no-data>
            Ainda não existem dados cadastrados
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <v-btn @click="editItem(item)" class="mr-4" icon color="black">
              <svg-icon type="mdi" :path="editar"></svg-icon>
            </v-btn>
            <v-btn @click="deleteItem(item)" class="mr-4" icon color="black">
              <svg-icon type="mdi" :path="excluir"></svg-icon>
            </v-btn>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
</template>
  
<script>
  import svcClientes from "../../services/svcClientes";
  import svcCidades from "../../services/svcCidades";
  import svcBairros from "../../services/svcBairros";
  import SvgIcon from '@jamescoyle/vue-icon';
  import { mdiPencil, mdiDelete, mdiMagnify, mdiOfficeBuilding } from '@mdi/js';
  import { encriptar } from "../../config/cripto";
  
  
  export default {
    components: {
          SvgIcon
      },
    data() {
      return {
        editar: mdiPencil,
        excluir: mdiDelete,
        pesquisar: mdiMagnify,
        icone: mdiOfficeBuilding,
        search: "",
        clientes: [],
        headers: [
          { text: "ID", align: "start", sortable: true, value: "id" },
          { text: "CPF/CNPJ", value: "cpfcnpj" },
          { text: "Nome", value: "nome" },
          { text: "Logradouro", value: "logradouro" },
          { text: "Número", value: "numero" },
          { text: "Complemento", value: "complemento" },
          { text: "Cidade", value: "id_cidade" },
          { text: "Bairro", value: "id_bairro" },
          { text: "Telefone", value: "telefone" },
          { text: "Ações", align: "center", value: "actions", sortable: false },
        ],
        dialog: false,
        dialogDelete: false,
        editedIndex: -1,
        editedItem: {
          id: "",
          cpfcnpj: "",
          logradouro: "",
          numero: "",
          complemento: "",
          id_cidade: "",
          id_bairro: "",
          telefone: "",
          logomarca: ""
        },
        defaultItem: {
          id: "",
          cpfcnpj: "",
          logradouro: "",
          numero: "",
          complemento: "",
          id_cidade: "",
          id_bairro: "",
          telefone: "",
          logomarca: ""
        },
        cidades: [],
        cidade: {
          id: "",
          nome: "",
          uf: "",
        },
        bairros: [],
        bairro: {
          id: "",
          id_cidade: "",
          nome: "",
        },
        isSelecting: false
      };
    },
  
  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
  },

  methods: {
    buscaCidade(item) {
      var cidade = this.cidades.filter(d => d.id === item.id_cidade);
      return cidade[0].nome + ' - ' + cidade[0].uf;
    },

    listaCidades() {
      svcCidades
        .listar()
        .then((resp) => {
          this.cidades = resp.data;
        })
        .catch((error) => {
          alert(error.response.data.mensagem);
          localStorage.clear();
          this.$router.push("/login");
        });
    },

    buscaBairro(item) {
      var bairro = this.bairros.filter(d => d.id === item.id_bairro);
      return bairro[0].nome;
    },

    listaBairros() {
      svcBairros
        .listar(0)
        .then((resp) => {
          this.bairros = resp.data;
        })
        .catch((error) => {
          alert(error.response.data.mensagem);
          localStorage.clear();
          this.$router.push("/login");
        });
    },

    lista() {
      svcClientes
        .listar()
        .then((resp) => {
          this.clientes = resp.data;
        })
        .catch((error) => {
          alert(error.response.data.mensagem);
          localStorage.clear();
          this.$router.push("/login");
        });
    },

    newItem() {
      this.$router.push("/clientes/new");
    },

    editItem(item) {
      this.$router.push("/clientes/edit/" + encriptar(item.id));
    },

    deleteItem(item) {
      this.editedIndex = this.clientes.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    deleteItemConfirm() {
      svcClientes
        .excluir(this.editedItem.id)
        .then((resp) => {
          if (resp.status == 200) {
            this.lista();
          } else {
            alert("Erro excluindo um cliente.");
          }
        })
        .catch((error) => {
          alert(error);
        });
      this.closeDelete();
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
  },

  mounted() {
    this.listaCidades();
    this.listaBairros();
    this.lista();
  },
};
</script>

<style scoped>
#tb {
  background-color: white;
  /* #dbdbdb; */
}
</style>
  